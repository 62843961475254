import Vue from 'vue'
import { trapFocus, removeTrapFocus } from '@shopify/theme-a11y'
import { disablePageScroll, enablePageScroll } from 'scroll-lock'
import { HEADER_MOBILE_BREAKPOINT } from '@/lib/consts'
import { on } from 'dom-event'
import state from '@/lib/appState'
import { trigger } from '@/lib/utils'

Vue.component('nav-main', {
  data() {
    return {
      isMenuOpen: false,
      isOnMobile: false,
      openNavSearch: false,
      state
    }
  },
  watch: {
    isMenuOpen(value) {
      if (value) {
        if (this.isOnMobile) {
          setTimeout(() => {
            this.$refs.navInnerLeft.focus()
            trapFocus(this.$refs.navInnerLeft)
            this.$refs.toggleMenuBtn.focus()
          }, 200)
          document.body.style.position = 'relative'
          disablePageScroll(this.$refs.navMenuWrapper)
        }
      } else {
        const links = document.querySelectorAll('.js-menu-item')
        if (links && links.length) {
          links.forEach((link) => {
            link.__vue__.closeSubMenu()
          })
        }

        if (this.isOnMobile) {
          document.body.removeAttribute('style')
          enablePageScroll()
          removeTrapFocus()

          setTimeout(() => {
            this.$refs.toggleMenuBtn.focus()
          }, 200)
        }
      }
    },
  },
  computed: {
    itemCount () {
      const items = this.state.cart.items || []
      return this.countQuantity(items)
    }
  },
  mounted() {
    this.isOnMobile = window.innerWidth <= HEADER_MOBILE_BREAKPOINT

    on(window, 'resize', () => {
      this.isOnMobile = window.innerWidth <= HEADER_MOBILE_BREAKPOINT
    })
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen
    },
    toggleNavSearch() {
      this.openNavSearch = !this.openNavSearch
    },
    openMiniCart () {
      trigger('mini-cart-open', document.body)
    },
    toggleMiniCart () {
      if (!this.state.isMiniCartLoaded) {
        this.state.isMiniCartLoaded = true
        this.state.isMiniCartOpen = !this.state.isMiniCartOpen
        if (this.state.isMiniCartOpen) {
          trigger('mini-cart-open', document.body)
        }
        return
      }

      this.state.isMiniCartOpen = !this.state.isMiniCartOpen
      if (this.state.isMiniCartOpen) {
        trigger('mini-cart-open', document.body)
      }
    },
    countQuantity (items) {
      return (items || []).reduce((count, item) => {
        count += Number(item.quantity)
        return count
      }, 0)
    }
  }
})
